@import "../../../styles/imports/variables.css";
@import "../../../styles/imports/containers.css";

:root {
  --color-white-low-opacity: rgba(255, 255, 255, 0.2);
  --color-white-medium-opacity: rgba(255, 255, 255, 0.4);
  --button-size: 45px;
  --animation-ease-in-out: ease-in-out;
}

.container {
  /* This container is used to fill half of the background to avoid content being visible behind the arrow */
  position: absolute;
  top: calc(50% - (var(--button-size) / 2)); /* This calc is used to center content vertically */
  width: calc(var(--button-size) / 2); /* We only want to hide half of the content behind the arrow */
  height: var(--button-size);
  z-index: 10;
  background: var(--color-dark-blue);

  & .button {
    position: absolute;
    width: var(--button-size);
    height: var(--button-size);
    transform: translateX(1px); /* To fix layout */
    display: block;
    border: 1px solid var(--color-white);
    background: var(--color-white);
    border-radius: 50%;
    box-shadow: 2px 0 60px 0 var(--color-dark-blue);
    transition:
      background var(--transition-default-duration) var(--animation-ease-in-out),
      border var(--transition-default-duration) var(--animation-ease-in-out);

    &.inactive {
      border: 1px solid var(--color-white-low-opacity);
      background: var(--color-dark-blue);
      box-shadow: unset;

      & > i > svg {
        fill: var(--color-white-medium-opacity);
      }

      &:hover {
        border: 1px solid var(--color-white-low-opacity);
        background: var(--color-dark-blue);
        cursor: default;
      }
    }

    & > i {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      & > svg {
        width: 50%;
        transition: fill var(--transition-default-duration) var(--animation-ease-in-out);
        fill: var(--color-dark-blue);
      }
    }

    &:hover {
      border: 1px solid var(--color-yellow);
      background: var(--color-yellow);
      cursor: pointer;
    }
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
    transform: rotate(180deg);
  }
}

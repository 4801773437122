{
  "notFoundPage": {
    "mainMessage": "Страница не найдена",
    "detailedMessage": "Вы только что перешли по не существующему маршруту... печалька."
  },
  "buttons": {
    "buttonLearnMore": "Узнать больше",
    "buttonGithub": "Github",
    "buttonTwitter": "Twitter",
    "buttonCommunity": "Сообщество",
    "buttonAddLanguage": "Добавьте ваш язык"
  },

  "navBar": {
    "item1": "Начать",
    "item2": "Руководства",
    "item3": "API",
    "item4": "Open Source"
  },

  "hero": {
    "welcomeMessage": {
      "long": "Полный P2P протокол, написанный целиком на JavaScript",
      "short": "Протокол P2P JavaScript"
    },
    "textDescription": {
      "long": "Helia прокладывает путь для браузерной реализации протокола IPFS. Будучи написанным полностью на JavaScript, он способен работать в браузерах, Service Worker'ах, веб-расширениях и на Node.js, открывая дверь в мир возможностей.",
      "short": "Helia способен работать в браузерах, Service Worker'ах, веб-расширениях и на Node.js, открывая дверь в мир возможностей."
    },
    "currentVersion": {
      "long": "Текущая версия: {version}",
      "short": "v{version}"
      },
    "latestUpdate": {
      "long": "Последнее обновление: {date}",
      "short": "Обновлено {date}"
      },
    "downloadsLastMonth": "Загрузок в прошлом месяце: {count}",
    "errorPckMessage": "Не удалось получить данные пакета IPFS.",
    "animationButton": {
      "enable": "Включить анимацию",
      "disable": "Отключить анимацию"
    }
  },

  "features": {
    "sectionTitle": "Возможности",
    "sectionDesc": "Оснащенная всем, что вы знаете и любите в IPFS. Эта реализация также содержит некоторые дополнительные исключительные возможности для браузеров. Это набор инструментов для снабжения всех ваших приложений DWeb.",
    "list": [
      {
        "title": "Запуск на Node.js и в браузере",
        "desc": "Helia работает в исходном виде на Node.js, Electron и в любом современном браузере. Также по изначальному замыслу он способен к настройке, чтобы вы могли использовать его в вашей любимой рабочей среде."
      },
      {
        "title": "Реализует полный набор IPFS.",
        "desc": "Ни одно свойство не было упущено. Helia это не облегченный клиент, это полная реализация протокола IPFS."
      },
      {
        "title": "Используйте PubSub для общенияс другими пирами в реальном времени.",
        "desc": "Узлы IPFS могут создавать топологические сети, основанные на актуальных темах, для вещания событий в реальном времени."
      },
      {
        "title": "Добавлять и считывать файлы отовсюду внутри сети IPFS.",
        "desc": "IPFS создан для использования могущества адресации по содержимому для нахождения узлов в сети, хранящих содержимое, которое вы ищете. В тот же способ вы можете добавлять данные, а другие узлы смогут найти их."
      },
      {
        "title": "Используйте DAG API для проохождения по любой структуре данных, имеющих хеш-ссылку",
        "desc": "IPFS использует [IPLD](https://ipld.io/), межпланетно связанные данные, модель, которая дает вам возможность взаимодействовать с данными из разных источников, таких как блокчейны и прочие."
      },
      {
        "title": "libp2p встроен",
        "desc": "[libp2p](https://libp2p.io/) это модульный стек протоколов, созданный для IPFS и теперь вы также можете использовать его напрямую через IPFS для своих приложений."
      },
      {
        "title": "Запустите это как службу",
        "desc": "Helia также имеет возможность быть запущенным как служба, чтобы вы могли взаимодействовать с ним, используя HTTP API, с которым вы знакомы из go-ipfs."
      },
      {
        "title": "Создавать стабильные адреса для переменчивых данных",
        "desc": "Он идет в комплекте с IPNS, межпланетной системой имен, с помощью которой можно создавать изменяемые указатели (записи), чтобы развертывать обновления в аутентифицированный и сертифицированный способ."
      }
    ]
  },

  "gettingStarted": {
    "sectionTitle": "Начать",
    "sectionDesc": "IPFS предоставляет вам базовый компонете, адресованный по содержимому, для всех ваших данных в DWeb. Вы можете сделать данные доступными для сети или получить существующие данные с помощью их CID, идентификатора содержимого. Попробуйте ниже или пройдите наши [уроки ProtoSchool](https://proto.school/course/ipfs)!",
    "addDataToIPFS": "Добавление данных в IPFS",
    "output": "Вывод",
    "getDataFromIPFS": "Получение данных из IPFS",
    "usingJavascript": "Использование JavaScript в браузере или Node.js",
    "usingCli": "Использование CLI",
    "usingGateway": "Использование HTTP-шлюза"
  },

  "serviceWorker": {
    "sectionTitle": "Шлюз Service Worker'а",
    "sectionDesc": "Превратите эту траницу в полноценный шлюз IPFS, не прикасаясь ни к каким серверам и не используя HTTP для получения содержимого из сети IPFS! Да, вы прочитали это правильно, шлюз Service Worker'а вместе с Helia могут напрямую соединяться с сетью IPFS.",
    "activationSuccessTitle": "Поздравляем! Теперь ваш браузер это полнофункциональный шлюз IPFS.",
    "activationSuccessText": "Загружайте любое содержимое так же, как вы бы делали это в обычный способ с помощью https://gateway.ipfs.io. Вот несколько примеров, которые стоит попробовать:\n- [ipfs.tech - /ipfs/{ipfsWebsiteHash}](https://js.ipfs.tech/ipfs/{ipfsWebsiteHash})\n- [js.ipfs.tech - /ipfs/{jsIpfsWebsiteHash}](https://js.ipfs.tech/ipfs/{jsIpfsWebsiteHash})\n- [awesome.ipfs.tech - /ipfs/{awesomeIpfsWebsiteHash}](https://js.ipfs.tech/ipfs/{awesomeIpfsWebsiteHash})\n- [peerpad.net - /ipfs/{peerpadWebsiteHash}](https://js.ipfs.tech/ipfs/{peerpadWebsiteHash})\n\nПопробуйте любой другой хеш, открыв https://js.ipfs.tech/ipfs/<your hash>",
    "toggleText": "Активировать Service Worker",
    "activatingToggleText": "Service Worker активируется...",
    "activationErrorMessage": "При активации Service Worker'а произошла ошибка.",
    "deactivationErrorMessage": "При деактивации Service Worker'а произошла ошибка.",
    "nonRootScopeWarningMessage": "Service Worker не может быть активирован при просмотре через этот URL. Вместо этого попробуйте https://js.ipfs.tech.",
    "incompatibleMessageText": "Этот браузер не поддерживает Service Worker'ы.",
    "activatedToggleText": "Деактивировать Service Worker."
  },

  "whatArePeopleBuilding": {
    "sectionTitle": "Что люди разрабатывают на нем",
    "sectionDesc": "",
    "list": [
      {
        "desc": "Архив всего интернета, но децентрализованный. Получите доступ к архиву из более чем 40m единиц через DWeb. Комбинируя шлюз сидов и оболочку на JavaScript для Firefox и Chrome."
      },
      {
        "desc": "PeerPad это редактордля совместной работы в реальном времени, который работает на децентрализованном web, построенный на IPFS и Y.js. Он не обращается ни ко вторым, ни к третьим лицам: все задействованные узлы общаются друг с другом напрямую без центральной службы. "
      },
      {
        "desc": "Наделите ваш браузер супер-способностями с помощью веб-расширения IPFS. IPFS-компаньон это расширение, которое может быть использовано в Chrome, Firefox и браузерах Brave, чтобы буспрепятственно предоставить вам доступ к сети IPFS. Также он содержит дополнительные возможности, такие как передача файлов и API для веб-разработчиков."
      },
      {
        "desc": "[Orbit Chat](https://orbit.chat) это безсерверное дистрибутивное пиринговое чат-приложение, построенное на OrbitDB. OrbitDB испльзует [IPFS](https://ipfs.tech) в качестве хранилища данных и [IPFS Pubsub](https://blog.ipfs.io/29-js-ipfs-pubsub) для автоматической синхронизации баз данных с пирами."
      }
    ]
  },

  "whatYouCanBuild": {
    "sectionTitle": "Что вы можете построить с Helia",
    "sectionDesc": "Варианты использования Helia бесчисленны. Следующие примеры подают идею о том, что вы можете построить с Helia. Поэксперементируйте над ними!",
    "suggestion": {
      "text": "Есть предложения?",
      "linkText": "Поделитесь ими здесь!"
    }
  },

  "publicationsAndTalks": {
    "sectionTitle": "Публикации и обсуждения",
    "sectionDesc": "Следите за обновлениями проекта, демо и другими обсуждениями сообщества IPFS в JavaScript-проекте IPFS. Вскоре мы будем добавлять еще десятки обсуждений из встреч разработчиков IPFS."
  },

  "community": {
    "sectionTitle": "Сообщество",
    "sectionDesc": "Helia это проект с открытым исходным кодом под лицензией Apache/MIT от сообщества IPFS. Есть много способов, как вы можете поспособствовать: помочь в написании спецификаций, реализаций кода и инструментов с использованием Helia, создавать примеры и руководства, присоединиться к нашим [призывам рабочей группы](https://lu.ma/helia-wg) для обсуждения IPFS с нами.",
    "socialNetworksText": "Проводите время с нами!"
  },

  "footer": {
    "rightContent": "Helia основан и финансируем",
    "leftContent": "IPFS Foundation | За исключением оговоренных случаев, контент подлежит лицензии CC-BY 3.0"
  }
}

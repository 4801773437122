.app {
  /* Stretch app to fill our screen height */
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.children {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

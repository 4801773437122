@import "../../styles/imports/variables.css";
@import "../../styles/imports/containers.css";

.footer {
  position: relative;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  background: var(--color-dark-blue);
  color: var(--color-text-footer);
  font-size: 13px;

  & .container {
    width: var(--container-width);
    max-width: var(--max-container-width);
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .leftContent {
      margin-right: 25px;
    }

    & .rightContent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .text {
        margin-right: 7px;
        text-align: right;
      }

      & .protocolLogo {
        width: auto;
        height: 35px;
        fill: var(--color-text-footer);
      }
    }
  }

  a {
    color: var(--color-text-footer);
    text-decoration: none;
  }
  a:link, a:hover {
    color: #FFF;
  }
}

@media (max-width: 1000px) {
  .footer .container {
    width: var(--container-width-mobile);
    padding: 35px 0;
    flex-direction: column;
    text-align: center;

    & .leftContent {
      margin-bottom: 15px;
      margin-right: 0;
    }

    & .rightContent {
      max-width: 80%;
    }
  }
}

@import "../../../styles/imports/variables.css";
@import "../../../styles/imports/containers.css";

:root {
  --color-black-opacity: rgba(0, 0, 0, 0.3);
  --color-black-low-opacity: rgba(0, 0, 0, 0.1);
  --card-border-radius: 5px;
}

.container {
  padding: 15px;
  display: flex;

  & .leftContainer {
    max-width: 30%;
    margin-right: 15px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    & .topContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & .logoContainer {
        width: 70%;
        margin-bottom: 15px;

        & .logo {
          position: relative;
          width: 100%;

          & > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }
        }
      }

      & .desc {
        font-size: var(--typography-text-size-small);
        text-align: left;
        line-height: var(--line-height-small);
        letter-spacing: -0.1px;
      }
    }

    & .bottomContainer {
      margin-top: 15px;
    }
  }

  & .rightContainer {
    max-width: 70%;
    flex: 1;

    & .imageWrapper {
      position: relative;
      width: 100%;
      padding-bottom: 62.5%;
      box-shadow: 0 0 10px 0 var(--color-black-opacity);

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}

@container style(--layout-lte-small: true) {
  .container {
    width: 100%;
    margin: 10px;
    padding: 0;
    flex-direction: column-reverse;
    align-items: center;
    background: var(--color-white);
    border-radius: var(--card-border-radius);
    box-shadow: 0 0 20px -1px var(--color-black-low-opacity);

    & .leftContainer {
      max-width: 100%;
      margin-right: 0;
      align-items: center;

      & .topContainer {
        padding: 0 20px;

        & .logoContainer {
          width: 75%;
          max-width: 200px;
          margin: 30px 0;

          & .logo {
            width: 100%;
          }
        }
      }

      & .bottomContainer {
        width: 100%;

        & a div {
          width: 100%;
          border-radius: 0;
          border-bottom-right-radius: var(--card-border-radius);
          border-bottom-left-radius: var(--card-border-radius);
        }
      }
    }

    & .rightContainer {
      display: none;
    }
  }
}

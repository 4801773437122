@import "../../styles/imports/variables.css";
@import "../../styles/imports/containers.css";

.container {
  position: relative;
  width: 100%;
  z-index: 3;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: var(--color-white);
  color: var(--color-dark-blue);

  & .backgroundSvg {
    position: absolute;
    width: 100%;
    height: 300px;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    & .hex1,
    & .hex2 {
      width: 20%;
      height: 100%;
      fill: var(--color-hex-svg-fill);
    }

    & .hex1 {
      margin-top: 30%;
      margin-left: -7%;
      transform: rotate(180deg);
    }

    & .hex2 {
      margin-top: 2%;
      margin-right: 2%;
      transform: rotate(180deg);
    }
  }

  & .content {
    width: 100%;
    z-index: 2;
    padding: 10% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > * {
      width: var(--container-width);
      max-width: var(--max-container-width);

      &:last-child {
        width: 60%;
      }
    }

    & h1 {
      font-weight: var(--typography-text-weight-semibold);
      text-align: center;
      line-height: var(--line-height-large);
    }

    & .sectionDescription {
      display: flex;
      justify-content: center;

      & p {
        width: 60%;
        font-weight: var(--typography-text-weight);
        line-height: var(--line-height-medium);
      }
    }
  }
}

@container style(--layout-lte-small: true) {
  .container {
    & .backgroundSvg {
      display: none;
    }

    & .content {
      & > * {
        width: var(--container-width-mobile);

        &:last-child {
          width: 100%;
        }
      }

      & .sectionDescription p {
        width: 95%;
      }
    }
  }
}

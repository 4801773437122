@import "../../styles/imports/variables.css";
@import "../../styles/imports/containers.css";

.container {
  position: relative;
  width: 100%;
  z-index: 3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  color: var(--color-dark-blue);

  & .content {
    width: var(--container-width);
    max-width: var(--max-container-width);
    padding: 10% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & h1 {
      font-weight: var(--typography-text-weight-semibold);
      text-align: center;
      line-height: var(--line-height-large);
    }

    & .sectionDescription {
      width: 100%;
      display: flex;
      justify-content: center;

      & p {
        width: 60%;
        font-weight: var(--typography-text-weight);
        line-height: var(--line-height-medium);
      }
    }

    & .featuresContainer {
      margin-top: 5%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & .featuresItem {
        width: calc(25% - 25px);
      }
    }
  }
}

@container style(--layout-lte-medium: true) {
  .container .content .featuresContainer .featuresItem {
    width: calc(33.3% - 25px);
  }
}

@container style(--layout-lte-small: true) {
  .container .content {
    width: var(--container-width-mobile);

    & .featuresContainer .featuresItem {
      width: calc(50% - 15px);
    }

    & .sectionDescription p {
      width: 95%;
    }
  }
}

@container style(--layout-lte-xsmall: true) {
  .container .content .featuresContainer .featuresItem {
    width: 100%;
    padding: 0 30px;
  }
}

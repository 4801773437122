@import "../../styles/imports/variables.css";
@import "../../styles/imports/containers.css";

:root {
  --info-transition-duration: 1500ms;
}

@keyframes orbit {
  from { transform: rotateZ(0deg); }
  to { transform: rotateZ(360deg); }
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-dark-blue);
  color: var(--color-white);

  & .animationToggle {
    position: absolute;
    right: 20px;
    bottom: calc(var(--nav-bar-desktop-height) + 20px);
  }

  &.animationOff * {
    animation-play-state: paused !important;
  }

  & .localesBar {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  & .orbitContainer {
    position: absolute;
    top: -7%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .orbits {
      position: relative;
      width: 90%;
      height: 170%;
      display: flex;
      justify-content: center;
      align-items: center;

      & .outsideRing,
      & .middleRing,
      & .insideRing {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      & .outsideRing {
        animation: orbit 300s infinite linear;
      }

      & .middleRing {
        animation: orbit 200s infinite linear;
      }

      & .insideRing {
        animation: orbit 120s infinite linear;
      }

    }
  }

  & .content {
    width: var(--container-width);
    max-width: var(--max-container-width);
    min-height: 100vh;
    z-index: 1;
    padding: 80px 0 calc(var(--nav-bar-desktop-height) + 60px) 0;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .cubeWrapper {
      width: 29%;
      margin: 0 0 1.5% 0;

      & .cubeContainer {
        position: relative;
        width: 100%;
        padding-bottom: 96.8%;

        & .cube {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
    }

    & .long {
      display: block;
    }

    & .short {
      display: none;
    }

    & h1 {
      min-height: 48px;
      font-weight: var(--typography-text-weight-semibold);
      text-transform: none;
      text-align: center;
      line-height: var(--line-height-large);
    }

    & .textDesc {
      max-width: 70%;
      min-height: 54px;

      & p {
        font-size: var(--typography-text-size);
        font-weight: var(--typography-text-weight-light);
        text-align: center;
        line-height: var(--line-height-medium);
      }
    }

    & .infoContainer {
      width: 100%;
      min-height: 16px;
      margin-top: 2%;
      opacity: 0;
      color: var(--color-light-blue);
      font-family: var(--typography-roboto-text-font);
      font-size: var(--typography-text-size-mobile);
      transition: opacity var(--info-transition-duration) ease;

      &.show {
        opacity: 1;
      }

      & div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        & span:not(:last-child) {
          margin-right: 4%;
        }
      }
    }
  }
}

@container style(--layout-lte-large: true) {
  .container .content .cubeWrapper {
    width: 26%;
  }
}

@container style(--layout-lte-medium: true) {
  .container {
    & .orbitContainer {
      width: 125%;
    }

    & .content .textDesc {
      max-width: 95%;
    }
  }
}

@container style(--layout-lte-small: true) {
  .container {
    & .orbitContainer,
    & .animationToggle {
      display: none;
    }

    & .content {
      width: var(--container-width-mobile);
      padding: 30% 0 50px;

      & .cubeWrapper {
        width: 75%;
        max-width: 300px;
        margin: 0 0 10% 0;
      }

      & .long {
        display: none;
      }

      & .short {
        display: block;
      }

      & .textDesc p {
        font-size: var(--typography-text-size);
      }

      & .infoContainer div {
        flex-direction: column;

        & span,
        & span:not(:last-child) {
          margin: 12px 0 0 0;
        }
      }
    }
  }
}

@container style(--layout-gte-xxlarge: true) {
  .container {
    & .orbitContainer {
      width: 75%;
    }
  }
}

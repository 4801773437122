@import "../../../styles/imports/variables.css";
@import "../../../styles/imports/containers.css";

:root {
  --transition-duration-double: calc(var(--transition-default-duration) * 2);
  --transition-duration-half: calc(var(--transition-default-duration) / 2);
}

.container {
  width: 100%;

  & .navBarContainer {
    width: 100%;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    transition: background var(--transition-duration-double) ease;

    &.navBarContainerScrolled {
      background: var(--color-menu-dark-blue);
      box-shadow: 0 5px 24px 0 rgba(14, 22, 48, 0.4);
      transition: all var(--transition-default-duration) ease;
    }

    & .hamburger {
      display: inline-block;
      cursor: pointer;

      & .bar1,
      & .bar2,
      & .bar3 {
        width: 30px;
        height: 2px;
        margin: 5px 0;
        background: var(--color-white);
        transition:
          transform var(--transition-duration-half) ease,
          opacity var(--transition-duration-half) ease;
      }

      &.hamburgerOpened {
        & .bar1 {
          transform: rotate(-45deg) translate(-4px, 5px);
        }

        & .bar2 {
          opacity: 0;
        }

        & .bar3 {
          transform: rotate(45deg) translate(-5px, -6px);
        }
      }
    }
  }

  & .localesDropdown {
    position: relative;
    top: 2px;
    right: -5px;
  }

  & .menuList {
    max-height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: var(--color-menu-dark-blue);
    box-shadow: 0 5px 24px 0 rgba(14, 22, 48, 0.4);
    transition: max-height var(--transition-duration-half) ease;

    & li {
      padding-left: calc(5% + 30px);

      & .menuLink {
        padding: 10px 20px;
        display: block;
        color: var(--color-white);
        font-size: var(--typography-text-size-large);
        text-decoration: none;
        text-transform: uppercase;
      }

      &.githubContributers {
        display: flex;
        align-items: center;

        & > iframe {
          width: 100px;
          height: 23px;
        }
      }

      &:last-child {
        margin-bottom: 25px;
      }
    }
  }
}

@container style(--layout-gte-medium: true) {
  .container {
    display: none;
  }
}

@import "../../../styles/imports/variables.css";
@import "../../../styles/imports/containers.css";

.featureItem {
  width: calc(25% - 25px);
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .image {
    width: 70%;
    margin-bottom: 15px;
  }

  & .title {
    font-weight: var(--typography-text-weight-semibold);
    line-height: var(--line-height-small);
  }

  & .description {
    font-size: var(--typography-text-size-small);
    font-weight: var(--typography-text-weight);
    line-height: var(--line-height-xsmall);

    & p:last-child {
      margin-bottom: 0;
    }
  }
}

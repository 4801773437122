@import "../../../styles/imports/variables.css";

.wrapper {
  width: 100%;

  & .switch {
    position: relative;
    width: 60px;
    height: 34px;
    display: inline-block;
    vertical-align: middle;

    & .slider {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid var(--color-white);
      cursor: pointer;
      transition: var(--transition-default-duration);

      &::before {
        content: "";
        position: absolute;
        bottom: 3px;
        left: 4px;
        width: 26px;
        height: 26px;
        background-color: var(--color-white);
        transition: var(--transition-default-duration);
      }

      &.active {
        &::before {
          transform: translateX(24px);
          background-color: var(--color-yellow);
        }
      }

      &.round {
        border-radius: 34px;

        &::before {
          border-radius: 50%;
        }
      }
    }
  }

  & .title {
    margin-left: 15px;
    vertical-align: middle;
    color: var(--color-white);
    transition: color var(--transition-default-duration);

    &.active {
      color: var(--color-yellow);
    }
  }

  &.incompatible {
    & .switch {
      opacity: 0.5;
      pointer-events: none;
    }

    & .title {
      opacity: 0.5;
    }
  }

  &.inProgress {
    & .switch {
      opacity: 0.5;
      pointer-events: none;
    }

    & .title {
      color: var(--color-yellow);
    }
  }

  & .tooltip {
    max-width: 75%;
    padding: 14px 20px;
  }
}

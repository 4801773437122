@import "../../../styles/imports/variables.css";
@import "../../../styles/imports/containers.css";

:root {
  --typography-menu-text-size: calc(var(--typography-text-size) + 1px);
}

.container {
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--nav-bar-desktop-height);
  padding: 20px 0;
  display: flex;
  background: var(--color-menu-dark-blue);
  font-size: var(--typography-menu-text-size);
  text-align: center;

  & .navBarMenu {
    width: 100%;
    display: flex;
    justify-content: center;

    & a,
    & .link {
      margin-right: 3%;
      display: flex;
      align-items: center;
      color: var(--color-white);
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      transition: color var(--transition-default-duration) ease;

      &:nth-child(4) {
        margin-right: 15px;
      }

      &:hover {
        color: var(--color-yellow);
      }
    }

    & > .starContainer {
      width: 100px;
      height: 24px;
      display: flex;
      align-items: center;

      & > iframe {
        width: 100%;
        height: 100%;
        margin-top: 3px;
      }
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  box-shadow: 0 5px 24px 0 rgba(14, 22, 48, 0.6);
}

/* Do not display container when screen is less or equal to 768px */
@container style(--layout-lte-small: true) {
  .container {
    display: none;
  }
}

@import "../../styles/imports/variables.css";
@import "../../styles/imports/containers.css";

.navBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: var(--nav-bar-desktop-height);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .target {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@container style(--layout-lte-small: true) {
  .navBar {
    position: fixed;
    top: 0;
    bottom: auto;
    height: auto;

    & .target {
      display: none;
    }
  }
}

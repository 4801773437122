@import "../../../styles/imports/variables.css";
@import "../../../styles/imports/containers.css";

:root {
  --player-border-radius: 10px;
}

.talksContainer {
  margin-top: 5%;
  display: flex;
  flex-direction: column;

  & .selectedVideo {
    position: relative;
    overflow: hidden;
    border-radius: var(--player-border-radius);
  }

  & .remainingVideosContainer {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@container style(--layout-lte-small: true) {
  .talksContainer {
    width: 100%;

    & .selectedVideo {
      display: none;
    }

    & .remainingVideosContainer {
      margin-top: 0;
    }
  }
}

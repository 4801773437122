@import "../../../styles/imports/variables.css";
@import "../../../styles/imports/containers.css";

:root {
  --player-border-radius: 3px;
}

.videoItemContainer {
  width: 100%;
  padding: 0 5px;

  & .videoPlaceholder,
  & .videoTitle {
    width: 100%;
  }

  & .videoPlaceholder {
    position: relative;
    overflow: hidden;
    padding-top: var(--video-ratio);
    border-radius: var(--player-border-radius);
    transition: opacity var(--transition-default-duration) ease;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    & .reactPlayer {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }

  & .videoTitle {
    margin-top: 20px;
    text-align: left;
    line-height: var(--line-height-small);
    letter-spacing: -0.1px;
  }
}

@container style(--layout-lte-small: true) {
  .videoItemContainer {
    & .videoTitle {
      display: none;
    }
  }
}

@import "../../styles/imports/variables.css";
@import "../../styles/imports/containers.css";

.localesBar {
  padding: 20px 0 25px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: var(--typography-text-size-small);
  transition: color var(--transition-default-duration) ease;

  & a {
    margin-top: 5px;
    padding: 0 7px;
    border-right: 1px solid var(--color-blue-dark-shade);
    color: var(--color-blue-dark-shade);
    text-decoration: none;

    &:last-child {
      border-right: 0;
    }

    &:hover {
      color: var(--color-white);

      &.addLanguage img use {
        fill: var(--color-white);
      }
    }

    &.active {
      color: var(--color-yellow);
    }

    &.addLanguage img {
      width: 15px;
      height: 15px;
      margin-right: 5px;

      & use {
        transition: fill var(--transition-default-duration) ease;
        fill: var(--color-blue-dark-shade);
      }
    }
  }
}

@container style(--layout-lte-small: true) {
  .localesBar {
    display: none;
  }
}

@import "../../styles/imports/variables.css";
@import "../../styles/imports/containers.css";

.container {
  position: relative;
  width: 100%;
  z-index: 3;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: var(--color-menu-dark-blue);
  color: var(--color-white);

  & .content {
    position: relative;
    width: var(--container-width);
    max-width: var(--max-container-width);
    padding: 10% 0 0 0;

    &.active {
      & .message {
        opacity: 1;
        transition-delay: 2300ms;
      }

      &.messageClosed {
        & .message {
          opacity: 0;
          transition-delay: 0s;
        }
      }
    }

    & .toggle {
      position: absolute;
      bottom: 8%;
    }

    & h1 {
      font-weight: var(--typography-text-weight-semibold);
      text-align: center;
      line-height: var(--line-height-large);
    }

    & .sectionDescription {
      width: 100%;
      margin-bottom: 3%;
      display: flex;
      justify-content: center;

      & p {
        width: 60%;
        font-weight: var(--typography-text-weight);
        text-align: center;
        line-height: var(--line-height-medium);
      }
    }

    & .gatewayContainer {
      position: relative;
      width: 100%;
      padding-bottom: 47%;

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  & .toastContainer {
    margin-top: calc(var(--nav-bar-desktop-height) + 3px);

    & .error {
      background-color: var(--color-error);
    }
  }
}

@container style(--layout-lte-small: true) {
  .container {
    & .content {
      width: var(--container-width-mobile);

      & .sectionDescription p {
        width: 95%;
      }

      & .toggle {
        bottom: calc(3% + 30px);
      }

      & .gatewayContainer {
        padding-bottom: 135%;
      }
    }
  }
}

@import "../../styles/imports/variables.css";

a.link {
  text-decoration: none;
  text-align: center;

  & .customButton {
    min-width: 150px;
    padding: 15px;
    display: inline-block;
    border-radius: 2px;
    color: var(--color-dark-blue);
    font-weight: var(--typography-text-weight-bold);
    text-transform: uppercase;
    letter-spacing: 0.8px;
    transition-duration: var(--transition-default-duration);

    &:hover {
      cursor: pointer;
    }
  }

  & .default {
    background-color: var(--color-yellow);
    color: var(--color-dark-blue);

    &:hover {
      background-color: var(--color-yellow-hover);
    }
  }

  & .twitter,
  & .github {
    color: var(--color-white);
  }

  & .twitter {
    background-color: var(--color-twitter);

    &:hover {
      background-color: var(--color-twitter-hover);
    }
  }

  & .github {
    background-color: var(--color-github);

    &:hover {
      background-color: var(--color-github-hover);
    }
  }
}

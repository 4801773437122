@import "../../styles/imports/variables.css";

.dropdown {
  position: relative;

  /* margin-left: calc(3% + 90px); */
  display: inline-block;

  & .openedDropdown {
    background-color: var(--color-menu-dark-blue) !important;
  }

  & .dropButton {
    padding: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: transparent;
    color: var(--color-white);
    cursor: pointer;
    transition: color var(--transition-default-duration) ease;

    &:hover {
      color: var(--color-yellow);
    }

    &:focus {
      outline: 0;
    }

    & img {
      height: 1em;
      margin-left: 5px;
    }

    & .arrowIcon {
      width: 8px;
      height: 8px;
      margin-left: 8px;
      border-top: 1px solid var(--color-white);
      border-right: 1px solid var(--color-white);
      pointer-events: none;
      transition:
        transform var(--transition-default-duration) ease,
        margin-top var(--transition-default-duration) ease,
        margin-bottom var(--transition-default-duration) ease;
    }

    & .arrowBottom {
      margin-top: -4px;
      transform: rotate(135deg);
    }

    & .arrowTop {
      margin-bottom: -8px;
      transform: rotate(-45deg);
    }
  }

  & .dropdownContent {
    position: absolute;
    width: 100%;
    z-index: 1;
    overflow: auto;
    display: none;

    /* height: 0; */
    background-color: var(--color-menu-dark-blue);
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.76);
    transition: height 1s ease;

    & a {
      width: 100%;
      padding: 7px 7px;
      display: block;
      float: left;
      color: var(--color-white);
      text-decoration: none;
      text-align: left;

      &:hover {
        color: var(--color-yellow);
      }

      & i {
        width: 18px;
        height: 18px;

        & svg {
          fill: var(--color-white);
        }
      }
    }
  }

  & .show {
    display: block;

    /* height: 100px; */
  }
}

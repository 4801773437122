@import "../../styles/imports/variables.css";
@import "../../styles/imports/containers.css";

:root {
  --codesandbox-editor-background-color: #1c2022;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-dark-blue);
  color: var(--color-white);

  & .content {
    width: 100%;
    padding: 10% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & h1,
    & .sectionDescription,
    & .iframeContainer,
    & .horizontalScroller {
      width: var(--container-width);
      max-width: var(--max-container-width);
    }

    & h1 {
      font-weight: var(--typography-text-weight-semibold);
      text-align: center;
      line-height: var(--line-height-large);
    }

    & .sectionDescription {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;

      & p {
        width: 60%;
        font-weight: var(--typography-text-weight);
        line-height: var(--line-height-medium);
      }
    }

    & .iframeContainer {
      position: relative;
      height: 500px;
      margin-top: 30px;

      & .loadingContainer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 1;
        background-color: var(--codesandbox-editor-background-color);
        transition: opacity var(--transition-default-duration) ease-in-out;

        &.hideLoading {
          z-index: -10;
          opacity: 0;
        }

        & .loadingCircle {
          width: 80px;
          height: 80px;
          border: 2px solid var(--color-text-footer);
          border-top: 2px solid var(--codesandbox-editor-background-color);
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }

        & span {
          margin-top: 20px;
          color: var(--color-text-footer);
          font-size: var(--typography-text-size-small);
        }
      }

      & iframe {
        /* We're setting both width and height to 1px and then
           using min-width and min-height to fix an iOS bug
           See https://github.com/scottjehl/Device-Bugs/issues/7#issuecomment-171988930
        */
        width: 1px;
        min-width: 100%;
        height: 1px;
        min-height: 100%;
        overflow: hidden;
        border: 0;
        border-radius: 4px;
      }
    }

    & p {
      margin-top: 35px;

      & > a {
        margin-left: 5px;
        color: var(--color-yellow);
        text-decoration: none;
        transition: color var(--transition-default-duration) ease;

        &:hover {
          color: var(--color-yellow-hover);
        }
      }
    }
  }
}

@container style(--layout-lte-small: true) {
  .container .content {
    & h1,
    & .sectionDescription,
    & .iframeContainer,
    & p {
      width: var(--container-width-mobile);
    }

    & p {
      display: flex;
      flex-direction: column;

      & > a {
        margin-top: 5px;
        margin-left: 0;
      }
    }

    & .horizontalScroller {
      width: 100%;
    }

    & .sectionDescription p {
      width: 95%;
    }
  }
}

@import "../../styles/imports/variables.css";

:root {
  --video-border-radius: 5px;
}

.youtube {
  position: relative;
  width: 100%;

  /* Use 75% for 4:3 videos */
  height: 0;
  overflow: hidden;
  padding-bottom: 56.23%;
  background: #000;
  border-radius: var(--video-border-radius);

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
    border-radius: var(--video-border-radius);
  }

  & .placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;

    /* This 'transform' is used to enable GPU as it boosts up browser repainting process */
    transform: translate3d(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: var(--video-border-radius);

    &.hide {
      display: none;
    }

    & .thumbnail {
      width: 100%;
    }

    & .play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 72px;
      height: 72px;
      margin: -36px 0 0 -36px;
      transform: scale(0.9);
      opacity: 0.85;
      transition: transform var(--transition-default-duration) ease, opacity var(--transition-default-duration) ease;
    }

    &.placeholderSmall .play {
      width: 44px;
      height: 44px;
      margin: -22px 0 0 -22px;
    }

    &.clicked {
      & .thumbnail,
      & .play {
        display: none;
      }
    }

    &:hover {
      cursor: pointer;

      & .play {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

{
  "notFoundPage": {
    "mainMessage": "الصفحة غير موجودة",
    "detailedMessage": "لقد ضربت للتو طريق غير موجود ... الحزن."
  },
  "buttons": {
    "buttonLearnMore": "أعرف أكثر",
    "buttonGithub": "جيثب",
    "buttonTwitter": "تويتر",
    "buttonCommunity": "المجتمع",
    "buttonAddLanguage": "أضف لغتك"
  },

  "navBar": {
    "item1": "البدء",
    "item2": "دروس",
    "item3": "API",
    "item4": "المصدر المفتوح"
  },

  "hero": {
    "welcomeMessage": {
      "long": "بروتوكول P2P كامل مكتوب بلغة JavaScript بالكامل",
      "short": "بروتوكول جافا سكريبت P2P"
    },
    "textDescription": {
      "long": "يمهد Helia الطريق لتطبيق المتصفح لبروتوكول IPFS. مكتوب بالكامل في JavaScript ، يتم تشغيله في متصفح ، عامل خدمة ، ملحق ويب و Node.js ، مما يفتح الباب أمام عالم من الاحتمالات.",
      "short": "يعمل Helia في متصفح وعامل خدمة وامتداد ويب و Node.js ، مما يفتح الباب أمام عالم من الاحتمالات."
    },
    "currentVersion": {
      "long": "النسخة الحالية:",
      "short": "v{version}"
      },
    "latestUpdate": {
      "long": "اخر تحديث: {date}",
      "short": "محدث {date}"
      },
    "downloadsLastMonth": "التنزيلات الشهر الماضي: {count}",
    "errorPckMessage": "تعذر جلب بيانات حزمة IPFS.",
    "animationButton": {
      "enable": "تمكين الرسوم المتحركة",
      "disable": "تعطيل الرسوم المتحركة"
    }
  },

  "features": {
    "sectionTitle": "المميزات",
    "sectionDesc": "معبأ بكل الأشياء التي تعرفها وتحبها في IPFS. يحمل هذا التطبيق أيضًا بعض الأشياء الرائعة الإضافية الفريدة للمتصفح. إنها مجموعة أدوات لتشغيل جميع تطبيقات DWeb الخاصة بك.",
    "list": [
      {
        "title": "تشغيل على Node.js والمتصفح",
        "desc": "يعمل Helia خارج الصندوق على Node.js و Electron وأي متصفح حديث. كما أنه قابل للتخصيص حسب التصميم بحيث يمكنك استخدامه في وقت التشغيل المفضل لديك."
      },
      {
        "title": "تنفيذ حزمة IPFS الكاملة",
        "desc": "لم تترك أي ميزة وراءها. Helia ليس عميل خفيف ، إنه التنفيذ الكامل لبروتوكول IPFS."
      },
      {
        "title": "استخدم PubSub للتواصل في الوقت الفعلي مع النظراء الآخرين",
        "desc": "يمكن لعقد IPFS إنشاء طبولوجيا الشبكة بناءً على الموضوعات التي تهم بث الأحداث في الوقت الفعلي."
      },
      {
        "title": "إضافة واستعادة الملفات من أي مكان في شبكة IPFS",
        "desc": "تم تصميم IPFS لاستخدام قوة عنونة المحتوى للعثور على العقد في الشبكة التي تحتوي على المحتوى الذي تبحث عنه. بنفس الطريقة ، يمكنك إضافة البيانات وستجده العقد الأخرى أيضًا."
      },
      {
        "title": "استخدم واجهة برمجة تطبيقات DAG للتنقل عبر أي بنية بيانات مرتبطة بالتجزئة",
        "desc": "يستخدم IPFS [IPLD] (https://ipld.io/) ، البيانات المرتبطة بين الكواكب ، وهو نموذج يمكنك من التفاعل مع البيانات من مصادر متعددة ، مثل git و blockchains والمزيد."
      },
      {
        "title": "تم بناء libp2p",
        "desc": "[libp2p] (https://libp2p.io/) هو مكدس الشبكات المعياري الذي تم إنشاؤه لـ IPFS ويمكنك الآن استخدامه من خلال IPFS لتطبيقك مباشرة أيضًا."
      },
      {
        "title": "قم بتشغيله كحفل",
        "desc": "يأتي Helia أيضًا بخيار تشغيله كبرنامج خفي حتى تتمكن من التفاعل معه باستخدام واجهة برمجة تطبيقات HTTP المألوفة لك من go-ipfs."
      },
      {
        "title": "إنشاء عناوين مستقرة لتغيير البيانات",
        "desc": "لأنه يأتي مع IPNS ، نظام التسمية بين الكواكب ، طريقة لتأليف مؤشرات (سجلات) قابلة للتغيير لتوزيع التحديثات بطريقة مصدق عليها ومعتمدة."
      }
    ]
  },

  "gettingStarted": {
    "sectionTitle": "ابدء",
    "sectionDesc": "يمنحك IPFS أساسيات عنونة المحتوى لجميع بياناتك على DWeb. يمكنك إتاحة البيانات للشبكة أو جلب البيانات الموجودة من خلال CID الخاص بها ، معرّف المحتوى. جربه أدناه أو استكشف [دروس ProtoSchool] (https://proto.school/course/ipfs)!",
    "addDataToIPFS": "إضافة البيانات إلى IPFS",
    "output": "مردود",
    "getDataFromIPFS": "الحصول على البيانات من IPFS",
    "usingJavascript": "استخدام جافا سكريبت في المتصفح أو Node.js",
    "usingCli": "باستخدام CLI",
    "usingGateway": "باستخدام مدخل HTTP"
  },

  "serviceWorker": {
    "sectionTitle": "مدخل عامل الخدمة",
    "sectionDesc": "حول هذه الصفحة إلى مدخل IPFS كاملة دون لمس أي خوادم أو استخدام HTTP لجلب المحتوى من شبكة IPFS! نعم ، لقد قرأتها بشكل صحيح ، يمكن أن تتصل بمدخل عامل الخدمة مع Helia مباشرة بشبكة IPFS.",
    "activationSuccessTitle": "تهانينا! متصفحك الآن مدخل IPFS تعمل بكامل طاقتها",
    "activationSuccessText": "قم بتحميل أي محتوى كما تفعل عادةً بواسطة  https://gateway.ipfs.io. إليك بعض الأمثلة لتجربتها:\n- [ipfs.tech - /ipfs/{ipfsWebsiteHash}](https://js.ipfs.tech/ipfs/\n{ipfsWebsiteHash}\n- [js.ipfs.tech - /ipfs/{jsIpfsWebsiteHash}](https://js.ipfs.tech/ipfs/\n{jsIpfsWebsiteHash}\n- [awesome.ipfs.tech - /ipfs/{awesomeIpfsWebsiteHash}](https://js.ipfs.tech/ipfs/\n{awesomeIpfsWebsiteHash}\n - [peerpad.net - /ipfs/{peerpadWebsiteHash}](https://js.ipfs.tech/ipfs/\n{peerpadWebsiteHash}\nجرب أي تجزئة أخرى عن طريق الفتح /https://js.ipfs.tech/ipfs <your hash>",
    "toggleText": "تمكين عامل الخدمة",
    "activatingToggleText": "تنشيط عامل الخدمة ...",
    "activationErrorMessage": "حدث خطأ عند تمكين عامل الخدمة.",
    "deactivationErrorMessage": "حدث خطأ عند تعطيل عامل الخدمة.",
    "nonRootScopeWarningMessage": "لا يمكن تنشيط عامل الخدمة عند المشاهدة من عنوان URL هذا. حاول في https://js.ipfs.tech بدلاً من ذلك.",
    "incompatibleMessageText": "هذا المتصفح لا يدعم عمال الخدمة.",
    "activatedToggleText": "تعطيل عامل الخدمة"
  },

  "whatArePeopleBuilding": {
    "sectionTitle": "ما يبنيه الناس معها",
    "sectionDesc": "",
    "list": [
      {
        "desc": "أرشيف الإنترنت بأكمله ، ولكنه لامركزي. الوصول إلى الأرشيف أكثر من 40 مليون عنصر عبر DWeb. الجمع بين بوابة البذر وواجهة مستخدم جافا سكريبت لمتصفح Firefox و Chrome."
      },
      {
        "desc": "PeerPad هو محرر تعاوني في الوقت الحقيقي يعمل على شبكة الويب اللامركزية ، مبني على أعلى IPFS و Y.js. لا يستخدم أي طرف ثالث أو طرف ثالث: كل العقد المشاركة تتحدث مباشرة مع بعضها البعض دون خدمة مركزية."
      },
      {
        "desc": "امنح متصفحك صلاحيات فائقة باستخدام ملحق ويب IPFS. يعد IPFS Companion امتدادًا يمكن استخدامه في Chrome و Firefox و Brave Browsers لمنحك الوصول إلى شبكة IPFS بسلاسة. كما أنه يحزم ميزات إضافية مثل مشاركة الملفات وواجهات برمجة التطبيقات لمطوري الويب."
      },
      {
        "desc": "[Orbit Chat] (https://orbit.chat) هو تطبيق دردشة بدون خادم وموزع من نظير إلى نظير مبني على قمة OrbitDB. يستخدم OrbitDB [IPFS] (https://ipfs.tech) كمخزن للبيانات و [IPFS Pubsub] (https://blog.ipfs.io/29-js-ipfs-pubsub) لمزامنة قواعد البيانات تلقائيًا مع أقرانهم."
      }
    ]
  },

  "whatYouCanBuild": {
    "sectionTitle": "ما يمكنك بناءه باستخدام Helia",
    "sectionDesc": "هناك خيارات غير محدودة لاستخدام Helia. توفر لك الأمثلة التالية فكرة عما يمكنك بناءه باستخدام Helia. جربهم في الخارج!",
    "suggestion": {
      "text": "هل لديك اقتراحات؟",
      "linkText": "شاركهم هنا!"
    }
  },

  "publicationsAndTalks": {
    "sectionTitle": "المنشورات والمحادثات",
    "sectionDesc": "شاهد تحديثات المشروع والعروض التوضيحية والمحادثات الأخرى من قبل مجتمع IPFS حول مشروع JavaScript الخاص بـ IPFS. سنضيف عشرات المحادثات الأخرى قريبًا من IPFS Dev Meetings."
  },

  "community": {
    "sectionTitle": "المجتمع",
    "sectionDesc": "Helia هو مشروع مفتوح المصدر مرخص من Apache/MIT من مجتمع IPFS. هناك العديد من الطرق التي يمكنك المساهمة بها: المساعدة في كتابة المواصفات وتنفيذ التعليمات البرمجية والأدوات باستخدام Helia والأمثلة الحرفية والبرامج التعليمية ، والانضمام إلى [مكالمات مجموعة العمل] (https://github.com/ipfs/team-mgmt# اتصال متزامن) لمناقشة IPFS معنا.",
    "socialNetworksText": "تعال اقضي وقتك!"
  },

  "footer": {
    "rightContent": "تم بدء Helia برعاية",
    "leftContent": "مختبرات البروتوكول | باستثناء ما هو مذكور ، تم ترخيص المحتوى CC-BY 3.0"
  }
}
